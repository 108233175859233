<template>
  <div class="resetpasword-box">
    <div class="rsb">
      <div class="form-box">
          <div>
              <el-alert :title="$t('avatarSelect.TopTip')" type="warning" show-icon></el-alert>
          </div>
        <div>
          <div class="form-label">{{ $t('avatarSelect.NewPwd') }}</div>
          <CInput v-model="pwd" type="password" :invalid-feedback="errorBackPassword" :is-valid="validatorPassword" valid-feedback="" />
        </div>
        <div>
          <div class="form-label">{{ $t('avatarSelect.ConfirmNewPassword') }}</div>
          <CInput v-model="rpwd" type="password" :invalid-feedback="errorBackConfirm" :is-valid="validatorConfirm" valid-feedback="" />
        </div>
        <div>
          <div class="form-label">{{ $t('avatarSelect.EmailCode') }}</div>
          <div class="fl">
              <CInput style="width: 75%" autocomplete="username" v-model="code"></CInput>
          <div>
            <CButton
              color="success"
              style="margin-bottom: 1rem;width:100px"
              block
              :disabled="dib"
              @click="reqCode"
              >{{ cgan == "" ? getVc : cgan }}</CButton
            >
          </div>
          </div>
          <div>
               <el-alert :title="$t('avatarSelect.BottomTip')" type="info" show-icon></el-alert>
          </div>
          <div class="btnbx">
              <!-- <el-button @click="$router.push('/users/account')">{{ $t('avatarSelect.Cancel') }}</el-button> -->
              <!-- <el-button @click="modifyPwd" type="primary" style="background: #2EB85C;color: #fff">{{ $t('avatarSelect.Confirm') }}</el-button> -->
              <button @click="$router.push('/users/account')" style="width:70px;height:35px" class="btn hv" type="button">{{ $t('avatarSelect.Cancel') }}</button>
              <button style="width:70px;margin-left:.5rem" @click="modifyPwd" class="btn custer sbtn" type="button">{{ $t('avatarSelect.Confirm') }}</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { resetPwd } from "../../api/http"
import { Loading } from "element-ui";
export default {
  data() {
    return {
      pwd: "",
      rpwd: "",
      code: "",
      cgan: "",
      timerNum: 60,
      dib: false,
      timer: null,
      errorBackPassword: "",
      errorBackConfirm: ""
    };
  },
  methods: {
    // 發送驗證碼
    cellCode() {
      // this.reqCode()
      this.dib = true;
      clearInterval(this.timer);
      this.timer = setInterval(() => {
        this.timerNum--;
        // this.vcode = this.timerNum
        this.getVc = this.timerNum;
        // this.$t('RegisterFields.SendCode2') = this.timerNum
        if (this.timerNum == 0) {
          clearInterval(this.timer);
          // this.vcode = this.rcode
          //  this.vcode = this.$t('RegisterFields.SendCode')
          // this.vcode = this.getVc
          this.timerNum = 60;
          this.dib = false;
        }
      }, 1000);
    },
    // 验证确认密码
    validatorConfirm(val) {
      if(val === "") {
        return
      }
      if(val !== this.pwd) {
        this.errorBackConfirm = this.$t('RegisterFields.CRPTip')
        return false
      }
      return true
    },
    // 密码验证
    validatorPassword(val) {
      // 必须是数字和字母组合，并且要大于等于六位
      let reg = /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,}$/
      if(val === "") {
        this.errorBackPassword = ""
        return
      }
       if(reg.test(val)) {
        this.errorBackPassword = ""
        return true
      }else {
        this.errorBackPassword = this.$t('RegisterFields.PasswordTip')
        return false
      }
    },
    // 請求驗證碼
    reqCode() {
      let loading = Loading.service({ fullscreen: true });
      let data = {
        type: "get_email_code",
        code_type: "reset_pw",
        user_name: window.localStorage.getItem("user_name"),
      };
      let formData = new FormData();
      for (let k in data) {
        formData.append(k, data[k]);
      }
      this.$axios({
        method: "POST",
        url: "/request/user.php",
        data: formData,
      }).then((code) => {
        if (code.data.code == 200) {
          loading.close();
          this.$message.success(code.data.message);
          this.cellCode();
        } else {
          this.timerNum = 0;
          clearInterval(this.timer);
          this.$message.error(code.data.message);
        }
      });
    },
    // 確認修改密碼
    modifyPwd() {
        if(this.rpwd == "" || this.code == "" || this.pwd== "") {
            return
        }
        let data = {
            type: "reset_password",
            user_name: window.localStorage.getItem("user_name"),
            new_password: this.rpwd,
            email_code: this.code,
            login_cookie: this.getCookie("ckey"),
        }
        let that = this
        resetPwd(data).then(res => {
            if(res.code == 200) {
              that.$confirm(
            that.$t("avatarSelect.ResetSucess"),"",
            {
              confirmButtonText: that.$t("avatarSelect.ResetSucessConfirm"),
              showClose: false,
              showCancelButton: false,
              closeOnClickModal: false,
              type: "warning",
            }
            )
            .then(() => {
               that.$store.dispatch("Logout")
            })
            .catch(() => {
            });
            }else {
                that.$message.error(res.message)
            }
        })
    },
    // 读取cookie
    getCookie(name) {
      var arr,
        reg = new RegExp("(^| )" + name + "=([^;]*)(;|$)");
      if ((arr = document.cookie.match(reg))) return unescape(arr[2]);
      else return null;
    },
    // 写入cookie
    setCookie(name, value) {
      var Days = 30;
      var exp = new Date();
      exp.setTime(exp.getTime() + Days * 24 * 60 * 60 * 1000);
      document.cookie =
        name + "=" + escape(value) + ";expires=" + exp.toGMTString();
    },
  },
  computed: {
    getVc: {
      get() {
        return this.$t("RegisterFields.SendCode2");
      },
      set(v) {
        console.log("vvv==>", v);
        this.cgan = v;
      },
    },
  },
};
</script>
<style scoped>
.sbtn:hover{
  background: rgba(0,190,164, .8) !important;
}
.sbtn:focus{
  border-color: #00BEA4;
  box-shadow: 0 0 0 .25rem rgba(0,190,164, .5) !important;
}
.custer{
  background: #00bea4;
  color: #fff; 
  width: 100%;
  outline:0;
}
/*  */
.cur{
  outline: none;
  border: none;
  color: #00BEA4;
  background: transparent;
  padding: 6px 12px;
  font-weight: bold;
}
.hv:hover{
  background: rgb(0,190,164);
  color: #fff;
  border-radius: 0.375rem;
}
.resetpasword-box {
  padding-left: 2.6rem;
  background: #fff;
  height: 100vh;
}
.rsb {
  width: 100%;
  padding: 1rem 0;
}
.form-box {
  width: 466px;
  margin: 0 auto;
}
.form-label {
  padding-bottom: 0.5rem;
}
.fl{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.btnbx{
    text-align: right;
    margin-top: 1rem;
}
</style>